export default function ({
    value,
    onChange,
    label,
}: {
    value: boolean;
    onChange: (v: boolean) => void;
    label: string;
}) {
    return (
        <div
            className="checkbox-input"
            onClick={() => onChange(value ? false : true)}
        >
            <div>
                <img src={value ? "/cross.png" : "empty.png"} />
            </div>
            <p>Dívida</p>
        </div>
    );
}
