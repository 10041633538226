import React, { useEffect, useState } from "react";
import Select from "react-select";
import {
    logoType,
    paymentMethodType,
    Product,
    ProductInput,
    Recit,
    recitType,
} from "../../types";
import {
    generateRandomId,
    getFormattedDate,
    getFormattedEuros,
    getImage,
    getPaymentMethod,
    getRecitType,
    updateImage,
} from "../../utils";
import "../../styles/home.scss";
import TextInput from "../input/TextInput";
import PasswordInput from "../input/PasswordInput";
import Button from "../input/Button";
import NumberInput from "../input/NumberInput";
import Checkbox from "../input/Checkbox";
import axios from "axios";

const groupOptions: { value: logoType; label: string }[] = [
    { value: "asj", label: "ASJ" },
    { value: "jdm", label: "Jovens do Mundo" },
];

const paymentOptions: { value: paymentMethodType; label: string }[] = [
    { value: "mbway", label: "MB Way" },
    { value: "cash", label: "Numerário" },
    { value: "transfer", label: "Transferência" },
];

const typeOptions: { value: recitType; label: String }[] = [
    { value: "fatura", label: "Fatura" },
    { value: "recibo", label: "Recibo" },
    { value: "fatura-recibo", label: "Fatura-Recibo" },
];

export default function ({ apiURL }: { apiURL: string }) {
    const [recit, setRecit] = useState<Recit>({
        type: "fatura-recibo",
        logo: "asj",
        name: "",
        products: [],
        timestamp: 0,
        total: { total: 0, assReq: false, pos: 0, neg: 0 },
        paymentMethod: undefined,
    });
    const [image, setImage] = useState<string>("");
    const [password, setPassword] = useState<string>("");
    const [product, setProduct] = useState<ProductInput>({
        description: "",
        value: 0,
        owe: false,
    });

    useEffect(() => {
        updateImage(setImage);
    }, [recit]);

    return (
        <div className="home">
            <h1>FORMULÁRIO</h1>
            <form>
                <p className="text-input-label">Tipo de fatura/recibo:</p>
                <Select
                    options={typeOptions}
                    value={typeOptions.find((v) => v.value === recit.type)}
                    onChange={(v) => {
                        setRecit({ ...recit, type: v?.value as recitType });
                    }}
                    isClearable={false}
                />
                <p className="text-input-label">Grupo:</p>
                <Select
                    options={groupOptions}
                    value={groupOptions.find((v) => v.value === recit.logo)}
                    onChange={(v) => {
                        setRecit({ ...recit, logo: v?.value as logoType });
                    }}
                    isClearable={false}
                />
                <TextInput
                    value={recit.name}
                    onChange={(v) => setRecit({ ...recit, name: v })}
                    label="Name"
                />
                <p className="text-input-label">Método de pagamento:</p>
                <Select
                    options={paymentOptions}
                    value={paymentOptions.find(
                        (v) => v.value === recit.paymentMethod
                    )}
                    onChange={(v) => {
                        setRecit({
                            ...recit,
                            paymentMethod: v?.value as paymentMethodType,
                        });
                    }}
                    isClearable={true}
                />
                <h2>Adicionar artigo</h2>
                <TextInput
                    value={product.description}
                    onChange={(v) => setProduct({ ...product, description: v })}
                    label="Descrição"
                />
                <NumberInput
                    label="Valor"
                    onChange={(v) => setProduct({ ...product, value: v })}
                    value={product.value}
                    min={0}
                />
                <Checkbox
                    label="Dívida"
                    value={product.owe}
                    onChange={(v) => {
                        setProduct({ ...product, owe: v });
                    }}
                />
                <Button
                    label="Adicionar"
                    onClick={() => {
                        const arr: Product[] = [...recit.products];
                        const r = { ...recit, products: arr };
                        if (product.owe) {
                            product.value = product.value * -1;
                            r.total.neg = r.total.neg - product.value;
                        } else {
                            r.total.pos = r.total.pos + product.value;
                        }
                        r.products.push({
                            value: product.value,
                            description: product.description,
                        });
                        if (product.value < 0) r.total.assReq = true;
                        r.total.total = r.total.total + product.value;
                        setRecit(r);
                        setProduct({ description: "", value: 0, owe: false });
                    }}
                />
                <h2>Lista de artigos</h2>
                <div className="product-list">
                    {recit.products.map((p) => (
                        <React.Fragment key={generateRandomId(6)}>
                            <p>{p.description}</p>
                            <p>{getFormattedEuros(p.value)}</p>
                            <Button
                                label="Remover"
                                onClick={() => {
                                    const r = { ...recit };
                                    const arr = [...recit.products];

                                    const i = arr.findIndex(
                                        (f) =>
                                            f.description === p.description &&
                                            f.value === p.value
                                    );

                                    arr.splice(i, 1);

                                    if (p.value < 0) {
                                        let done = false;
                                        for (let h = 0; h < arr.length; h++) {
                                            if (arr[h].value < 0) {
                                                done = true;
                                                break;
                                            }
                                        }
                                        if (!done) {
                                            r.total.assReq = false;
                                        }
                                    }

                                    r.total.total = r.total.total - p.value;
                                    if (p.value < 0) {
                                        r.total.neg = r.total.neg + p.value;
                                    } else {
                                        r.total.pos = r.total.pos - p.value;
                                    }
                                    r.products = arr;

                                    setRecit(r);
                                }}
                                width={90}
                            />
                        </React.Fragment>
                    ))}
                </div>
            </form>

            <h1>HTML</h1>
            <div id="recit-container">
                <div id="recit-generator-id">
                    <img className="logo" src={getImage(recit.logo)} />
                    <p className="centered bold">{getRecitType(recit.type)}</p>
                    <p>Nome: {recit.name}</p>
                    <p>Data: {getFormattedDate(new Date(recit.timestamp))}</p>
                    <hr />
                    <p className="centered bold">Items</p>
                    <div className="products-grid">
                        {recit.products.map((o) => (
                            <React.Fragment key={generateRandomId(6)}>
                                <p>{o.description}</p>
                                <p>{getFormattedEuros(o.value)}</p>
                            </React.Fragment>
                        ))}
                    </div>
                    <hr />
                    <p className="centered bold">Informações de Pagamento</p>
                    <div className="products-grid2">
                        {recit.total.neg > 0 && recit.total.pos > 0 ? (
                            <>
                                <p>Total:</p>{" "}
                                <p>{getFormattedEuros(recit.total.pos)}</p>
                                <p>Dívida restituída:</p>
                                <p>{getFormattedEuros(recit.total.neg * -1)}</p>
                            </>
                        ) : undefined}
                        <p>A pagar:</p>{" "}
                        <p>{getFormattedEuros(recit.total.total)}</p>
                        {recit.paymentMethod ? (
                            <>
                                <p>Método pagamento:</p>
                                <p>{getPaymentMethod(recit.paymentMethod)}</p>
                            </>
                        ) : undefined}
                    </div>
                    {recit.total.assReq ? (
                        <>
                            <hr />
                            <p />
                            <p className="centered">
                                Ass: ________________________
                            </p>
                            <p className="small gray centered">
                                (Dívida paga ou parcialmente paga)
                            </p>
                        </>
                    ) : undefined}
                </div>
            </div>

            <h1>IMAGEM</h1>
            <img src={image} className="final-image" />

            <h1>GERAR</h1>
            <form>
                <PasswordInput
                    value={password}
                    onChange={(s) => setPassword(s)}
                    label="Password:"
                />
                <Button
                    label="Enviar"
                    onClick={() => {
                        sendData(apiURL, recit, password);
                    }}
                />
            </form>
        </div>
    );
}

async function sendData(apiURL: string, data: Recit, pass: string) {
    axios
        .post(`${apiURL}/create`, { pass, data })
        .then((e) => {
            if (e.data) {
                window.location.href = e.data.href;
            }
        })
        .catch(console.log);
}
