import { toPng } from "html-to-image";
import { logoType, paymentMethodType, recitType } from "./types";

export async function makeImage(): Promise<string> {
    const a = await toPng(
        document.getElementById("recit-generator-id") as HTMLElement
    );
    return a;
}

export function getImage(l: logoType) {
    switch (l) {
        case "asj":
            return "/logo_asj.png";
        case "jdm":
            return "/logo_jdm.png";
    }
}

export function getFormattedDate(d: Date) {
    return `${d.getDate().toLocaleString("pt", {
        style: "decimal",
        minimumIntegerDigits: 2,
    })}/${(d.getMonth() + 1).toLocaleString("pt", {
        style: "decimal",
        minimumIntegerDigits: 2,
    })}/${d.getFullYear()} - ${d.getHours().toLocaleString("pt", {
        style: "decimal",
        minimumIntegerDigits: 2,
    })}:${d.getMinutes().toLocaleString("pt", {
        style: "decimal",
        minimumIntegerDigits: 2,
    })}:${d
        .getSeconds()
        .toLocaleString("pt", { style: "decimal", minimumIntegerDigits: 2 })}`;
}

export function getFormattedEuros(v: number): string {
    let n = v * 100;
    n = n % 100;
    return `${v < 0 ? "- " : ""}€${Math.abs(
        v < 0 ? Math.ceil(v) : Math.floor(v)
    ).toLocaleString("pt", {
        style: "decimal",
    })},${Math.abs(n).toLocaleString("pt", {
        style: "decimal",
        minimumIntegerDigits: 2,
    })}`;
}

export async function updateImage(
    setImage: (s: string) => void
): Promise<void> {
    setImage(await makeImage());
}

export function generateRandomId(size: number) {
    var result = "";
    var characters =
        "ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789";
    var charactersLength = characters.length;
    for (var i = 0; i < size; i++) {
        result += characters.charAt(
            Math.floor(Math.random() * charactersLength)
        );
    }
    return result;
}

export function getPaymentMethod(method: paymentMethodType): string {
    switch (method) {
        case "cash":
            return "Numerário";
        case "mbway":
            return "MB Way";
        case "transfer":
            return "Transferência";
    }
    return method;
}

export function getRecitType(type: recitType): string {
    switch (type) {
        case "fatura":
            return "Fatura";
        case "recibo":
            return "Recibo";
        case "fatura-recibo":
            return "Fatura-Recibo";
    }
    return type;
}
