import React from "react";
import {
    Route,
    BrowserRouter as Router,
    Routes,
    Outlet,
} from "react-router-dom";
import "./styles/global.scss";
import FinalRecit from "./components/pages/FinalRecit";
import Home from "./components/pages/Home";

export default class extends React.Component {
    constructor(props: {}) {
        super(props);
    }

    render() {
        const apiURL = `${window.location.protocol}//${window.location.hostname}:${window.location.port}/api`;
        return (
            <div className="App">
                <Router>
                    <Routes>
                        <Route
                            path="/"
                            element={<Home apiURL={apiURL} />}
                        ></Route>
                        <Route
                            path="/:id/:secret"
                            element={<FinalRecit apiURL={apiURL} />}
                        ></Route>

                        <Route path="*"></Route>
                    </Routes>
                </Router>
            </div>
        );
    }
}
