import "../../styles/input.scss";

export default function ({
    value,
    onChange,
    label,
    min,
    max,
}: {
    value: number;
    label?: string;
    onChange: (v: number) => void;
    min?: number;
    max?: number;
}) {
    return (
        <>
            {label ? <p className="text-input-label">{label}</p> : undefined}
            <input
                className="text-input"
                type="number"
                value={value}
                onChange={(e) => {
                    let v = parseFloat(e.target.value);
                    if (min || min === 0) {
                        if (v < min) {
                            v = min;
                        }
                    }
                    if (max || max === 0) {
                        if (v > max) {
                            v = max;
                        }
                    }
                    onChange(v);
                }}
            />
        </>
    );
}
