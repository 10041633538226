import "../../styles/input.scss";

export default function ({
    onClick,
    label,
    width,
}: {
    onClick: () => void;
    label: string;
    width?: number;
}) {
    return (
        <button
            style={{ width: width }}
            className="button-input"
            onClick={(e) => {
                e.preventDefault();
                onClick();
            }}
        >
            {label}
        </button>
    );
}
