import { useParams } from "react-router-dom";
import {
    generateRandomId,
    getFormattedDate,
    getFormattedEuros,
    getImage,
    getPaymentMethod,
    getRecitType,
    updateImage,
} from "../../utils";
import React, { useEffect, useState } from "react";
import { Recit } from "../../types";
import "../../styles/finalrecit.scss";
import axios from "axios";
import QRCode from "react-qr-code";

export default function ({ apiURL }: { apiURL: string }) {
    const { id, secret } = useParams();
    const [recit, setRecit] = useState<Recit>({
        type: "fatura-recibo",
        logo: "asj",
        name: "",
        products: [],
        timestamp: 0,
        total: { assReq: false, total: 0, neg: 0, pos: 0 },
        paymentMethod: "cash",
    });
    const [image, setImage] = useState<string>("");
    useEffect(() => {
        getRecit(apiURL, id as string, secret as string, setRecit);
    }, []);
    useEffect(() => {
        updateImage(setImage);
    }, [recit]);
    if (recit.timestamp === 0) {
        return (
            <div>
                <h1>Recibo não encontrado!</h1>
                <p>Id: {id}</p>
                <div id="recit-generator-id" />
            </div>
        );
    } else {
        return <RecitComponent image={image} recit={recit} id={id as string} />;
    }
}

function RecitComponent({
    recit,
    image,
    id,
}: {
    recit: Recit;
    image: string;
    id: string;
}) {
    return (
        <div className="finalrecit">
            <h1>HTML</h1>
            <div id="recit-container">
                <div id="recit-generator-id">
                    <img className="logo" src={getImage(recit.logo)} />
                    <p className="centered bold">{getRecitType(recit.type)}</p>
                    <p>Nome: {recit.name}</p>
                    <p>Data: {getFormattedDate(new Date(recit.timestamp))}</p>
                    <hr />
                    <p className="centered bold">Items</p>
                    <div className="products-grid">
                        {recit.products.map((o) => (
                            <React.Fragment key={generateRandomId(6)}>
                                <p>{o.description}</p>
                                <p>{getFormattedEuros(o.value)}</p>
                            </React.Fragment>
                        ))}
                    </div>
                    <hr />
                    <p className="centered bold">Informações de Pagamento</p>
                    <div className="products-grid2">
                        {recit.total.neg > 0 && recit.total.pos > 0 ? (
                            <>
                                <p>A pagar:</p>{" "}
                                <p>{getFormattedEuros(recit.total.pos)}</p>
                                <p>Dívida restituída:</p>
                                <p>{getFormattedEuros(recit.total.neg * -1)}</p>
                            </>
                        ) : undefined}
                        <p>Total:</p>{" "}
                        <p>{getFormattedEuros(recit.total.total)}</p>
                        {recit.paymentMethod ? (
                            <>
                                <p>Método pagamento:</p>
                                <p>{getPaymentMethod(recit.paymentMethod)}</p>
                            </>
                        ) : undefined}
                    </div>
                    {recit.total.assReq ? (
                        <>
                            <hr />
                            <p />
                            <p className="centered">
                                Ass: ________________________
                            </p>
                            <p className="small gray centered">
                                (Dívida paga ou parcialmente paga)
                            </p>
                        </>
                    ) : undefined}
                    <hr />
                    <QRCode value={window.location.href} className="qr-code" />
                </div>
            </div>

            <h1>IMAGEM</h1>
            <a
                download={`${getRecitType(recit.type)}-${id}.png`}
                href={image}
                className="button-input"
            >
                Recibo - Download
            </a>
            <img src={image} className="final-image" />
        </div>
    );
}

async function getRecit(
    apiURL: string,
    id: string,
    secret: string,
    setRecit: (r: Recit) => void
) {
    axios
        .get(`${apiURL}/get/${id}/${secret}`)
        .then((e) => {
            if (e.data) setRecit(e.data);
        })
        .catch(console.log);
}
