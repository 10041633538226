import "../../styles/input.scss";

export default function ({
    value,
    onChange,
    label,
}: {
    value: string;
    label?: string;
    onChange: (v: string) => void;
}) {
    return (
        <>
            {label ? <p className="text-input-label">{label}</p> : undefined}
            <input
                className="text-input"
                type="text"
                value={value}
                onChange={(e) => onChange(e.target.value)}
            />
        </>
    );
}
